<template>
  <div class="main" data-title="轮播列表" v-title>
    <div class="page-header">
      <div class="page-header-body">
        <h2 class="page-header-title">轮播列表</h2>
      </div>
    </div>
    <el-card class="main-content register-box" shadow="hover">
      <div class="jue-card-header">
        <div class="jue-card-title">轮播列表</div>
        <div class="jue-card-btns">
          <el-button
            @click="add"
            class="btn"
            icon="el-icon-edit"
            type="primary"
            v-if="getAuth('add')"
          >新建</el-button>
        </div>
      </div>

      <div class="table-wrapper">
        <el-table
          :data="newsData"
          :style="'width: '+$store.state.tableWidth"
          class="table"
          style="width: 100%"
        >
          <el-table-column label="标题" prop="title"></el-table-column>
          <el-table-column label="图片" prop="img">
            <template slot-scope="scope">
              <el-image :src="scope.row.img" fit="contain" style="height: 100px"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="排序" prop="order"></el-table-column>
          <el-table-column label="生效" prop="status">
            <template slot-scope="scope">{{scope.row.status == 1 ? '生效' : '失效'}}</template>
          </el-table-column>
          <el-table-column label="发布日期" prop="created_at"></el-table-column>
          <el-table-column fixed="right" label="操作" width="100px">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row)" size="small" type="text" v-if="getAuth('edit')">修改</el-button>
              <span class="jue-divider" v-has="'news-remove'"></span>

              <el-button
                @click="deleteItem(scope.$index, scope.row)"
                class="btn-delete"
                size="small"
                type="text"
                v-if="getAuth('delete')"
              >删除</el-button>

              <!-- <el-button class="btn-delete" size="small" type="text">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination
          :page-size="key.count"
          :total="total"
          @current-change="handleCurrentChange"
          background
          layout="prev, pager, next"
        ></el-pagination>
      </div>
    </el-card>
    <slide-edit ref="slide"></slide-edit>
  </div>
</template>
<script>
import SlideEdit from '@/components/news/SlideEdit.vue'
export default {
  components: {
    SlideEdit,
  },
  data() {
    return {
      base: {
        popover: false,
      },
      begin_end_date: null,
      total: 0,
      key: {
        page: 1,
        count: 15,
        begin_date: '',
        end_date: '',
        title: '',
        status: 0,
      },
      newsData: [],
    }
  },
  mounted() {
    this.getNewsData()
  },
  methods: {
    getAuth(str) {
      let pa = this.$route.path.split('/')
      if (this.$store.state.auth[pa[1]][pa[2]][str] == undefined) {
        return false
      } else {
        return true
      }
    },
    add() {
      this.$refs.slide.add()
    },
    edit(item) {
      this.$refs.slide.edit(item)
    },
    changeDate() {
      if (this.begin_end_date == null) {
        this.key.begin_date = ''
        this.key.end_date = ''
      } else {
        this.key.begin_date = this.begin_end_date[0]
        this.key.end_date = this.begin_end_date[1]
      }
    },
    resetSeachKey() {
      this.begin_end_date = null
      this.key.title = ''
      this.key.status = 0
    },
    //点击分页
    handleCurrentChange(val) {
      this.key.page = val
      this.getNewsData()
    },
    changeStatus(data) {
      this.$axios
        .post('/news/set_status', {
          news_id: data.news_id,
          status: data.status,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    getNewsData() {
      this.$axios.post('/banner/lists', this.key).then((res) => {
        if (res.data.code == 200) {
          this.newsData = res.data.data.list
          this.total = res.data.data.total
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    deleteItem(index, item) {
      this.$confirm('此操作将永久删除 ' + item.title + ' , 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .post('/banner/remove', {
              sys_id: item.sys_id,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.getNewsData()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang="less" scoped>
.table-wrapper {
  margin: -20px -20px 0;
}

.form {
  /deep/ .el-form-item__label {
    padding: 0;
  }
  /deep/ .el-select,
  /deep/ .el-date-editor {
    width: 100%;
  }
}

.footer {
  text-align: right;
  padding: 20px 0;
}
</style>